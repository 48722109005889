import { PlusIcon } from '@mentimeter/ragnar-visuals';
import React, { useState } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { ModalOpeningContextT } from '../types';

export const StartFromScratch = ({
  isFirst,
  createPresentation,
  modalOpeningContext,
  closeModal,
  onStartFromScratchInEditor,
}: {
  isFirst: boolean;
  createPresentation: ({ serieName }: { serieName: string }) => Promise<void>;
  modalOpeningContext: ModalOpeningContextT;
  closeModal: () => void;
  onStartFromScratchInEditor?: () => void;
}) => {
  const isInEditor = modalOpeningContext === 'editor';

  const clickHandler = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (isInEditor) {
      closeModal();
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onStartFromScratchInEditor && onStartFromScratchInEditor();
      return;
    }

    if (isFirst) {
      await createPresentation({ serieName: 'My First Presentation' });
    } else {
      await createPresentation({ serieName: 'Untitled Presentation' });
    }
  };

  return <StartFromScratchUI onClick={clickHandler} />;
};

export function StartFromScratchUI({
  text = 'Blank',
  onClick,
}: {
  text?: string;
  onClick(e: React.MouseEvent): void;
}) {
  const [hovering, setHovering] = useState(false);

  return (
    <Box width="100%" height="100%">
      <Box
        onClick={onClick}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        width="100%"
        justifyContent="center"
        alignItems="center"
        borderWidth={1}
        borderColor={hovering ? 'primary' : 'borderWeak'}
        borderRadius={1}
        borderStyle="solid"
        minHeight="142px"
        extend={() => ({
          cursor: 'pointer',
          transition: 'border-color .15s ease-out',
          aspectRatio: '16 / 9',
        })}
      >
        <PlusIcon size={7} color={hovering ? 'primary' : 'neutral'} />
      </Box>
      <Text mt={2} color={hovering ? 'primary' : 'text'}>
        {text}
      </Text>
    </Box>
  );
}
