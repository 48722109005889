import type { PublicTemplatesCategoryT } from '@mentimeter/http-clients';
import { type ReactNode } from 'react';
import { Badge } from '@mentimeter/ragnar-ui/badge';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalRoot,
  ModalTrigger,
} from '@mentimeter/ragnar-ui/modal';
import { Section } from '@mentimeter/ragnar-ui/section';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { FormStateT, ThumbnailT } from '../types';
import { DescriptionEditor } from './DescriptionEditor';
import { ThumbnailSelect } from './ThumbnailSelect';
import { CategorySelect } from './CategorySelect';
import { TemplateWarning } from './TemplateWarning';

export function TemplateEditorContent({
  open,
  onOpenChange,

  templateName,
  templateLink,
  isPublished,
  categories,
  thumbnails,

  formState,
  onChange,
  onSaveChanges,
  onTogglePublish,
  saveState,
  children,
}: {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;

  templateName: string;
  templateLink: string;
  isPublished: boolean;
  categories: PublicTemplatesCategoryT[];
  thumbnails: ThumbnailT[];

  formState: FormStateT;
  onChange: (newFormState: FormStateT) => void;
  onSaveChanges: () => void;
  onTogglePublish: () => void;
  saveState: 'saved' | 'saving' | 'unsaved';

  children: ReactNode;
}) {
  return (
    <ModalRoot open={open} onOpenChange={onOpenChange}>
      <ModalTrigger>{children}</ModalTrigger>

      <ModalContainer size="large">
        <ModalHeader>
          Template {`"${templateName}"`}
          <Box mb="space2">
            <PublishStatusBadge isPublished={isPublished} />
          </Box>
        </ModalHeader>

        <ModalBody>
          <Section
            id="recommended-template-description"
            borderBottomWidth={1}
            borderColor="#DDD"
            borderStyle="solid"
            mb="space4"
            pb="space4"
          >
            <TemplateWarning />
          </Section>

          <Section id="recommended-template-category" title="Category">
            <Text color="text" lineHeight="none" mb={2}>
              Templates require a category to be visible in the template pane
              and modal
            </Text>
            <CategorySelect
              selectedCategoryId={formState.categoryId}
              categories={categories}
              onChange={(selectedCategory) => {
                onChange({ ...formState, categoryId: selectedCategory.id });
              }}
            />
          </Section>

          <Section
            id="recommended-template-thumbnail"
            title="Thumbnail slide"
            description="Select the slide to be used for showcasing the template"
          >
            <ThumbnailSelect
              selectedThumbnailId={formState.questionId}
              thumbnails={thumbnails}
              onChange={(selectedThumbnail) => {
                onChange({ ...formState, questionId: selectedThumbnail.id });
              }}
            />
          </Section>

          <Section id="recommended-template-description" title="Description">
            <DescriptionEditor
              description={formState.description}
              onChange={(newDescription) => {
                onChange({ ...formState, description: newDescription });
              }}
            />
          </Section>
        </ModalBody>

        <ModalFooter>
          <Button href={templateLink} target="_blank" mr="auto">
            Open at location
          </Button>

          <Button
            type="submit"
            variant={isPublished ? 'negative' : 'positive'}
            onClick={onTogglePublish}
          >
            {isPublished ? 'Unpublish' : 'Publish'}
          </Button>
          <Button
            disabled={saveState !== 'unsaved'}
            type="submit"
            variant="primary"
            onClick={onSaveChanges}
          >
            {saveState === 'unsaved' ? 'Save changes' : 'Saved'}
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
}

function PublishStatusBadge({ isPublished }: { isPublished: boolean }) {
  return isPublished ? (
    <Badge
      type="text"
      color="onPositiveWeak"
      bg="positiveWeak"
      text="Published"
    />
  ) : (
    <Badge
      type="text"
      color="onNegativeWeak"
      bg="negativeWeak"
      text="Unpublished"
    />
  );
}
