import type { PublicTemplateT } from '@mentimeter/http-clients';
import { isTouchDevice } from '@mentimeter/ragnar-device';
import { blackAlpha300 } from '@mentimeter/ragnar-colors';
import * as React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { RagnarButtonState } from '@mentimeter/ragnar-ui/button';
import { TemplateSlidePreview } from './src/components/TemplateSlidePreview';
import type { ModalOpeningContextT, ModalTrackingContextT } from './types';
import { useAddPublicTemplate } from './hooks/useAddPublicTemplate';

interface TemplateCardT extends BoxT {
  template: PublicTemplateT;
  trackingContext: ModalTrackingContextT;
  modalOpeningContext: ModalOpeningContextT;
  isNewPresentationMode: boolean;
  closeModal: () => void;
  insertAtIndex?: number;
  onPreview: (template: PublicTemplateT) => void;
}

export const TemplateCard = ({
  template,
  trackingContext,
  modalOpeningContext,
  isNewPresentationMode,
  closeModal,
  insertAtIndex = 0,
  onPreview,
  ...rest
}: TemplateCardT) => {
  const { preview_question_admin_key: questionId, series_id: presentationId } =
    template;

  const { handleClickClone, state: addTemplateButtonState } =
    useAddPublicTemplate({
      template,
      isNewPresentationMode,
      insertAtIndex,
      modalOpeningContext,
      trackingContext,
      closeModal,
    });

  return (
    <TemplateCardUI
      template={template}
      onPreview={onPreview}
      onAdd={handleClickClone}
      addTemplateButtonState={addTemplateButtonState}
      {...rest}
    >
      <TemplateSlidePreview
        seriesId={presentationId}
        displaySlideId={questionId}
      />
    </TemplateCardUI>
  );
};

interface TemplateCardUIT extends BoxT {
  children: React.ReactNode;
  template: PublicTemplateT;
  showSlideCount?: boolean;
  addTemplateButtonState: RagnarButtonState | undefined;
  onPreview?: ((template: PublicTemplateT) => void) | undefined;
  onAdd: (e: React.MouseEvent) => void;
}

export function TemplateCardUI({
  children,
  template,
  showSlideCount = true,
  addTemplateButtonState,
  onAdd,
  onPreview,
  ...rest
}: TemplateCardUIT) {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const isTouch = isTouchDevice();

  return (
    <Box
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      width="100%"
      extend={() => ({
        cursor: 'pointer',
      })}
      {...rest}
    >
      <Box borderRadius="lg" overflow="hidden" width="100%">
        {!isTouch && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            borderRadius="lg"
            zIndex={1}
            extend={({ theme }) => ({
              transition: `box-shadow ${theme.durations[0]}s ease-in-out`,
              boxShadow: `0 0 0 2px ${blackAlpha300} inset`,
              ...(isHovering && {
                boxShadow: `0 0 0 2px ${theme.colors.secondary} inset, 0 0 0 4px ${theme.colors.bg} inset`,
              }),
            })}
          >
            <Box
              justifyContent="flex-end"
              flexDirection="row"
              alignItems="flex-end"
              width="100%"
              height="100%"
              zIndex={3}
              p="space4"
              gap="space2"
              extend={({ theme }) => ({
                opacity: isHovering ? 1 : 0,
                transition: `opacity ${theme.durations[0]}s ease-in-out`,
              })}
            >
              {onPreview && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onPreview(template);
                  }}
                  size="compact"
                  variant="tertiary"
                >
                  Preview
                </Button>
              )}
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onAdd(e);
                }}
                aria-label="Add template"
                variant="primary"
                size="compact"
                state={addTemplateButtonState}
              >
                Add
              </Button>
            </Box>
          </Box>
        )}

        {children}
      </Box>
      <Box pt="space4" pb="space5" width="100%">
        <Text
          fontSize="100"
          fontWeight="regular"
          lineHeight="normal"
          color="text"
          truncate
        >
          {template.name}
        </Text>
        {showSlideCount && (
          <Text
            fontSize="87.5"
            fontWeight="regular"
            lineHeight="normal"
            color="textWeaker"
          >
            {template.slide_count} slide
            {template.slide_count > 1 ? 's' : ''}
          </Text>
        )}
      </Box>
    </Box>
  );
}
