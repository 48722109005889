// This file is automatically generated. DO NOT EDIT.

import { type Series } from '../../../../types/response/Series';
import { type SlideDeck } from '../../../../types/response/SlideDeck';
import { fromSnakeKeys } from '@api/internal';

export interface PostInsertResponse {
  /** The series, will be null if series is migrated to a slide deck */
  series: Series | null;
  /** The slide deck of the series, null if not yet migrated */
  slideDeck: SlideDeck | null;
}

export async function postInsertResponse(
  response: Response,
): Promise<PostInsertResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostInsertResponse;
}
