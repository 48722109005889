import { LocalStorage } from '@mentimeter/storage';
import { SKIP_TEMPLATE_MODAL } from '@mentimeter/cookies/cookie-constants';
import { create } from 'zustand';

interface HasSkippedTemplateModalStore {
  hasSkippedTemplateModal: boolean;
  setHasSkippedTemplateModal: (value: boolean) => void;
}

export const useHasSkippedTemplateModalStore =
  create<HasSkippedTemplateModalStore>((set) => ({
    hasSkippedTemplateModal: Boolean(
      LocalStorage.getJSONItem(SKIP_TEMPLATE_MODAL),
    ),
    setHasSkippedTemplateModal(hasSkippedTemplateModal) {
      LocalStorage.setJSONItem<boolean>({
        type: 'functional',
        key: SKIP_TEMPLATE_MODAL,
        value: hasSkippedTemplateModal,
      });
      set((state) => ({
        ...state,
        hasSkippedTemplateModal,
      }));
    },
  }));
