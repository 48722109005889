import { getReadRequest } from '@core-api/templates/templates/series/{series_id}/read';
import { createTemplatesSeriesCompatibilityLayer } from '@mentimeter/compatibility/templates';
import { seriesCacheKey, useData } from '@mentimeter/core-hooks';
import type { SlideDeck } from '@mentimeter/templates-schema/templates';
import { useSlideDeckFetcher } from '@mentimeter/migration-utils';
import { useCallback, useMemo } from 'react';
import { type SWRConfiguration } from 'swr';
import type { Series } from '@mentimeter/http-clients';

interface UseTemplatesSlideDeckOptions {
  swrConfig?: SWRConfiguration;
  getSeriesCacheKey?: (seriesId: string) => string | Array<string>;
}

// Equivalent to using useSWRImmutable
const defaultSwrConfig: SWRConfiguration = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

/**
 * Fetches a "series with a slide deck" with backwards compatibility to our legacy domain model.
 * @remarks Requires an **authenticated client** and it should **only** be used within the Templates app.
 */
export const useTemplatesSlideDeck = (
  seriesId: string,
  options: UseTemplatesSlideDeckOptions = {},
) => {
  const getCacheKey = options?.getSeriesCacheKey ?? seriesCacheKey;
  const cacheKey = getCacheKey(seriesId);

  const fetcher = useSlideDeckFetcher<Series | SlideDeck>(
    seriesId,
    getReadRequest,
  );

  const {
    data: series,
    error,
    revalidate,
    lazyData,
  } = useData<Series | SlideDeck>(
    {
      cacheKey,
      seriesId,
      fetcher,
    },
    { ...defaultSwrConfig, ...options.swrConfig },
  );

  const wrappedLazyData = useCallback(() => {
    const series = lazyData();
    return createTemplatesSeriesCompatibilityLayer(series);
  }, [lazyData]);

  return useMemo(() => {
    return {
      series: createTemplatesSeriesCompatibilityLayer(series),
      error,
      revalidate,
      lazyGetSeries: wrappedLazyData,
    };
  }, [series, error, revalidate, wrappedLazyData]);
};
