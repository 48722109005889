// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface GetReadRequest {
  region: 'eu' | 'us';
  seriesId: string;
}

export function getReadRequest(requestParams: GetReadRequest): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/templates/series/${requestParams.seriesId}/read`;
  return new Request(url, {
    method: 'GET',
  });
}
