import { type PublicTemplateT } from '@mentimeter/http-clients';
import { useRouter } from '@mentimeter/next-navigation';
import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { useDuplicateTemplateBySeriesId } from '@mentimeter/template-hooks';
import type { ModalOpeningContextT, ModalTrackingContextT } from '../types';
import { useCopyPublicTemplate } from './useCopyPublicTemplate';

interface Props {
  template: PublicTemplateT;
  isNewPresentationMode: boolean;
  insertAtIndex: number;
  trackingContext: ModalTrackingContextT;
  modalOpeningContext: ModalOpeningContextT;
  closeModal: () => void;
}

export const useAddPublicTemplate = ({
  template,
  isNewPresentationMode,
  insertAtIndex,
  trackingContext,
  modalOpeningContext,
  closeModal,
}: Props) => {
  const router = useRouter();
  const isInEditor = modalOpeningContext === 'editor';

  const { cloneTemplate: cloneOutsideEditor, state: stateCloneOutsideEditor } =
    useDuplicateTemplateBySeriesId({
      context: trackingContext,
      successCallback: ({ seriesId }) => {
        router.push(
          buildPresentationPath({
            seriesId,
            mode: 'edit',
          }),
        );
      },
    });

  const {
    copyPublicTemplate: cloneInsideEditor,
    state: stateCloneInsideEditor,
  } = useCopyPublicTemplate({
    isNewPresentationMode,
    context: trackingContext,
    insertAtIndex,
    successCallback: () => {
      closeModal();
    },
  });

  const handleClickClone = (e: React.MouseEvent) => {
    e.preventDefault();

    if (isInEditor) {
      cloneInsideEditor(template.id);
    } else {
      cloneOutsideEditor(template.series_id);
    }
  };

  const state = isInEditor ? stateCloneInsideEditor : stateCloneOutsideEditor;

  return { handleClickClone, state };
};
