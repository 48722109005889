import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { userCache } from '@mentimeter/user';
import { useState, useCallback } from 'react';
import {
  postDuplicateRequest,
  postDuplicateResponse,
  type PostDuplicateRequest,
} from '@core-api/templates/templates/public/duplicate';

interface Props {
  context: PostDuplicateRequest['trackContext'];
  tags?: PostDuplicateRequest['tags'];
  successCallback?: (params: { seriesId: string }) => void;
}

const PUBLIC_TEMPLATES_REGION = 'eu';

export const useDuplicateTemplateBySeriesId = ({
  context,
  tags,
  successCallback = () => {},
}: Props) => {
  const [state, setState] = useState<ButtonT['state']>();

  const cloneTemplate = useCallback(
    async (copySeriesId: string) => {
      setState('loading');
      try {
        const request = postDuplicateRequest({
          region: userCache.region,
          userAuth: userCache.getToken(),
          source: {
            region: PUBLIC_TEMPLATES_REGION,
            seriesId: copySeriesId,
          },
          tags: tags ?? ['Example'],
          ...(context ? { trackContext: context } : {}),
        });

        const response = await fetch(request);

        if (!response.ok) {
          throw new Error('Failed to duplicate template series');
        }

        const { series } = await postDuplicateResponse(response);

        setState('success');
        successCallback?.({ seriesId: series.seriesId });
      } catch (error: any) {
        captureException(
          new MentiError('Failed to duplicate template series', {
            feature: 'copy-template-outside-editor',
            cause: { error },
          }),
        );
        setState('error');
      }
    },
    [tags, context, successCallback],
  );

  return {
    cloneTemplate,
    state,
  };
};
