import type { PublicTemplateT } from '@mentimeter/http-clients';
import { useMatch } from '@mentimeter/ragnar-device';
import { ArrowLeftIcon } from '@mentimeter/ragnar-visuals';
import React, { useMemo } from 'react';
import { trackUser } from '@api/tracking/client';
import { Box } from '@mentimeter/ragnar-ui/box';
import type { RagnarButtonState } from '@mentimeter/ragnar-ui/button';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Heading } from '@mentimeter/ragnar-ui/typography';
import { getQuestionTypesMappedByName } from '../TemplateModal/utils';
import { useAddPublicTemplate } from '../hooks/useAddPublicTemplate';
import type { ModalOpeningContextT, ModalTrackingContextT } from '../types';
import { DescriptionPreview } from '../src/description-preview';
import { useTemplatesSlideDeck } from '../hooks/useTemplatesSlideDeck';
import { TemplatePreview } from './TemplatePreview';
import { AddTemplateButton } from './AddTemplateButton';
import { DetailsSkeleton } from './DetailsSkeleton';

export const TemplateDetails = ({
  template,
  onBack,
  closeModal,
  trackingContext,
  modalOpeningContext,
  isNewPresentationMode,
  insertAtIndex,
}: {
  template: PublicTemplateT;
  onBack: () => void;
  closeModal: () => void;
  trackingContext: ModalTrackingContextT;
  modalOpeningContext: ModalOpeningContextT;
  isNewPresentationMode: boolean;
  insertAtIndex: number;
}) => {
  const [buttonState, setButtonState] = React.useState<RagnarButtonState>();

  const isMobileOrTablet = useMatch({ lessThan: 4 });
  const isMobile = useMatch({ lessThan: 2 });

  const onCloseModal = (): void => {
    closeModal();
    setButtonState(undefined);
  };

  const { handleClickClone, state: addTemplateButtonState } =
    useAddPublicTemplate({
      template,
      isNewPresentationMode,
      insertAtIndex,
      modalOpeningContext,
      trackingContext,
      closeModal: onCloseModal,
    });
  const { series: presentation } = useTemplatesSlideDeck(template.series_id);

  const questionTypesMappedByName = useMemo(() => {
    if (!presentation) return [];

    return getQuestionTypesMappedByName(presentation);
  }, [presentation]);

  React.useEffect(() => {
    setButtonState(addTemplateButtonState);
  }, [addTemplateButtonState]);

  React.useEffect(() => {
    trackUser({
      event: 'Opened template preview',
      properties: {
        context: trackingContext,
        template: template.name,
        'series id': template.series_id,
      },
    });
  }, [template, trackingContext]);

  return (
    <Box width="100%">
      <Button
        size="large"
        variant="subtle"
        onClick={onBack}
        iconLeading={ArrowLeftIcon}
      >
        Back
      </Button>
      <Box
        width="100%"
        height="100%"
        color="text"
        flexDirection={['column', 'column', 'row']}
        alignItems="stretch"
        pt={4}
      >
        <Box width={['100%', '100%', '348px']} mr={5}>
          {!presentation ? (
            <DetailsSkeleton />
          ) : (
            <>
              <Heading mb={2}>{template.name}</Heading>
              {template.description && (
                <DescriptionPreview description={template.description} />
              )}
              <Text
                color="text"
                fontSize={1}
                mb={2}
                mt="space4"
                fontWeight="semiBold"
              >
                Interactions included
              </Text>
              <Box flexDirection="row" flexWrap="wrap">
                {questionTypesMappedByName?.map((name) => {
                  return (
                    <Box
                      key={name}
                      mr={2}
                      px={2}
                      py={1}
                      mb={2}
                      borderColor="borderWeak"
                      borderStyle="solid"
                      borderWidth={2}
                      borderRadius={3}
                    >
                      <Text fontSize={1}>{name}</Text>
                    </Box>
                  );
                })}
              </Box>
              <Box mt={4}>
                <AddTemplateButton
                  handleClickClone={handleClickClone}
                  addTemplateButtonState={buttonState}
                />
              </Box>
            </>
          )}
        </Box>

        <Box
          overflow="auto"
          width="100%"
          extend={() => ({
            scrollbarGutter: 'stable', // Only solves scroll-bar flickering for modern browsers other than Safari
            flexShrink: isMobileOrTablet ? 'unset' : 1,
          })}
        >
          <Box
            display="grid"
            width="100%"
            extend={() => ({
              gridGap: '24px',
              gridTemplateColumns: isMobile
                ? 'repeat(1, 1fr)'
                : 'repeat(2, 1fr)',
            })}
          >
            {presentation?.questions.map((question, index) => {
              return (
                <TemplatePreview
                  key={question?.id || index}
                  questionId={question?.id}
                  questionTitle={question?.question}
                  presentationId={presentation.id}
                  slideNumber={index + 1}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
