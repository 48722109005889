// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostInsertRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The region of the source */
  publicTemplateRegion?: 'eu' | 'us';
  /** The id of the public template to copy from */
  publicTemplateId: number;
  /** Id of the series to add the templates slides to. */
  targetSeriesId: string;
  /** If defined, specificies the position where to place the templates slides */
  insertAtIndex?: number;
  trackContext: string;
}

export function postInsertRequest(requestParams: PostInsertRequest): Request {
  const data = {
    public_template_region: toSnakeKeys(requestParams.publicTemplateRegion),
    public_template_id: toSnakeKeys(requestParams.publicTemplateId),
    target_series_id: toSnakeKeys(requestParams.targetSeriesId),
    insert_at_index: toSnakeKeys(requestParams.insertAtIndex),
    track_context: toSnakeKeys(requestParams.trackContext),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/templates/public/series/insert`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
