import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Skeleton } from '../skeleton';
import { Box, type BoxT } from '../box';
import { Text } from '../text';
import { Tooltip, TriggerDescriptive } from '../tooltip';
import type { ChevronIconProps } from './ChevronIcon';
import { ChevronIcon } from './ChevronIcon';

export interface SectionT
  extends Omit<BoxT, 'id' | 'children' | 'onChange' | 'onClick'> {
  children?:
    | React.ReactNode
    | ((show: boolean, setShow: (show: boolean) => void) => React.ReactNode);
  id: string;
  title?: string | undefined;
  description?: string | undefined;
  badge?: React.ReactNode | undefined;
  toggable?: boolean | undefined;
  togglePlacement?: ChevronIconProps['togglePlacement'];
  loading?: boolean;
  initialShow?: boolean | undefined;
  onChange?: (arg0: boolean) => void;
  onClick?: React.MouseEventHandler;
}

export const Section = ({
  id,
  description,
  title,
  badge,
  toggable = false,
  loading = false,
  togglePlacement,
  initialShow = false,
  onChange,
  children,
  onClick,
  className,
  ...props
}: SectionT) => {
  const [show, setShow] = React.useState(initialShow);
  const canToggle = toggable && !loading;

  const augmentedOnClick = canToggle
    ? () => {
        setShow(!show);
        if (onChange) onChange(!show);
      }
    : undefined;

  const defaultClasses = ['w-full', 'mb-4', 'items-stretch'];

  return (
    <Box className={clsx(defaultClasses, className)} {...props}>
      <div className="flex flex-row w-full">
        <Box
          {...(canToggle && {
            onClick: onClick ? onClick : augmentedOnClick,
            'data-testid': 'settings-toggle',
          })}
          className={clsx([
            'flex-row',
            'items-center',
            'justify-items-center',
            canToggle ? 'cursor-pointer' : 'cursor-default',
          ])}
        >
          {title && loading ? (
            <Skeleton style={{ width: `${title.length}ch` }} />
          ) : (
            <>
              {title && (
                <Text
                  id={`${id}-label`}
                  variant="ragnarHeadline5"
                  className="text"
                >
                  {title}
                </Text>
              )}
              {description && (
                <Tooltip trigger={<TriggerDescriptive />}>
                  {description}
                </Tooltip>
              )}
            </>
          )}
          {badge && <Box className="ml-2">{badge}</Box>}
        </Box>
        {canToggle && (
          <ChevronIcon
            onClick={onClick ? onClick : augmentedOnClick}
            show={show}
            togglePlacement={togglePlacement}
          />
        )}
      </div>

      {typeof children === 'function' ? children(show, setShow) : children}
    </Box>
  );
};
