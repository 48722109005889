import { type ReactNode, useState } from 'react';
import type { Series } from '@mentimeter/http-clients';
import { useTemplatesSlideDeck } from '@mentimeter/templates-modal/hooks/useTemplatesSlideDeck';
import {
  useAdminTemplate,
  useTemplateCategories,
  useUpsertAdminTemplate,
} from '../hooks';
import { TemplateEditor } from './components/TemplateEditor';

export function TemplateEditorDataHandler({
  seriesId,
  children,
}: {
  seriesId: string;
  children: ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const { series } = useTemplatesSlideDeck(seriesId);
  const { data: categories } = useTemplateCategories();
  const { data: existingTemplate, isLoading: isLoadingTemplateData } =
    useAdminTemplate(seriesId);
  const { trigger: saveTemplateChanges } = useUpsertAdminTemplate(
    seriesId,
    existingTemplate?.id ?? null,
  );

  if (!categories || isLoadingTemplateData || !series) return null;

  const thumbnails = series.questions.map(({ id, question }) => ({
    id,
    title: question,
  }));
  const templateLink = getLinkToLocation(series);

  return (
    <TemplateEditor
      open={open}
      onOpenChange={setOpen}
      templateSeriesId={seriesId}
      templateName={series.name}
      templateLink={templateLink}
      existingTemplate={existingTemplate}
      thumbnails={thumbnails}
      categories={categories}
      onSave={saveTemplateChanges}
    >
      {children}
    </TemplateEditor>
  );
}

function getLinkToLocation(series: Series) {
  if (series.workspace_id) {
    if (series.folder_id) return `/app/workspace/folder/${series.folder_id}`;
    else return '/app/workspace';
  } else {
    if (series.folder_id) return `/app/folder/${series.folder_id}`;
    return `/app/dashboard`;
  }
}
