import type { Slide as TemplatesSlide } from '@mentimeter/templates-schema/templates';
import { commonSlidePropertyKeys } from '../../../common/constants';

export const templatesSlidePropertyKeys = [
  'slideId',
  'staticContent',
  'speakerNotes',
  'slideAdminKey',
  ...commonSlidePropertyKeys,
] as const satisfies Array<keyof TemplatesSlide>;
