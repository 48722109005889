import {
  Channels,
  SeriesPrivateEvents,
  usePublish,
} from '@mentimeter/realtime';
import { useCallback } from 'react';
import { userCache } from '@mentimeter/user';
import {
  postReplaceRequest,
  postReplaceResponse,
  type PostReplaceRequest,
} from '@core-api/templates/templates/public/series/replace';
import {
  postInsertRequest,
  postInsertResponse,
  type PostInsertRequest,
} from '@core-api/templates/templates/public/series/insert';
import {
  questionsCacheKey,
  resultCacheKey,
  seriesCacheKey,
} from '@mentimeter/core-hooks';
import { useSWRConfig } from 'swr';
import update from 'immutability-helper';

const PUBLIC_TEMPLATES_REGION = 'eu';

interface AddPublicTemplate {
  templateId: number;
  context: string;
}
export interface AddPublicTemplateToNewSeriesArgs extends AddPublicTemplate {
  replaceQuestionId: string;
}
export interface AddPublicTemplateToOldSeriesArgs extends AddPublicTemplate {
  insertAtIndex: number;
}

const replacePublicTemplate = async (
  params: Omit<PostReplaceRequest, 'userAuth' | 'region'>,
) => {
  const userAuth = userCache.getToken();
  const { region } = userCache;
  const request = postReplaceRequest({
    userAuth,
    region,
    ...params,
  });

  const response = await fetch(request);
  return await postReplaceResponse(response);
};

const insertPublicTemplate = async (
  params: Omit<PostInsertRequest, 'userAuth' | 'region'>,
) => {
  const userAuth = userCache.getToken();
  const { region } = userCache;
  const request = postInsertRequest({
    ...params,
    userAuth,
    region,
  });

  const response = await fetch(request);
  return await postInsertResponse(response);
};

export const usePublicTemplatesActions = (seriesId: string) => {
  const publish = usePublish({
    channel: Channels.SERIES_PRIVATE,
    value: seriesId,
  });
  const { mutate } = useSWRConfig();

  const addPublicTemplateToNewSeries = useCallback(
    async ({ templateId, context }: AddPublicTemplateToNewSeriesArgs) => {
      const response = await replacePublicTemplate({
        publicTemplateId: templateId,
        publicTemplateRegion: PUBLIC_TEMPLATES_REGION,
        targetSeriesId: seriesId,
        trackContext: context,
      });

      publish(SeriesPrivateEvents.UPDATE_NETWORK_CACHE, {
        cacheKey: questionsCacheKey(seriesId),
      });

      mutate(seriesCacheKey(seriesId));
      mutate(
        questionsCacheKey(seriesId),
        response.slideDeck
          ? response.slideDeck.slides
          : response.series?.questions,
        {
          revalidate: true,
        },
      );
      const questionId = response.slideDeck
        ? response.slideDeck.slides[0]?.slideAdminKey
        : response.series?.questions[0]?.id;
      if (questionId) {
        mutate(resultCacheKey(questionId));
      }

      return null;
    },

    [seriesId, publish, mutate],
  );

  const addPublicTemplateToOldSeries = useCallback(
    async ({
      insertAtIndex,
      templateId,
      context,
    }: AddPublicTemplateToOldSeriesArgs) => {
      publish(SeriesPrivateEvents.UPDATE_NETWORK_CACHE, {
        cacheKey: questionsCacheKey(seriesId),
      });
      const response = await insertPublicTemplate({
        publicTemplateId: templateId,
        publicTemplateRegion: PUBLIC_TEMPLATES_REGION,
        targetSeriesId: seriesId,
        insertAtIndex,
        trackContext: context,
      });
      const questions = response.slideDeck
        ? response.slideDeck.slides
        : response.series?.questions;

      // We only want to mutate the inserted slides,
      // otherwise the old slides rerenders as well
      const newSlides = questions?.slice(insertAtIndex);
      if (Array.isArray(questions)) {
        mutate<typeof questions>(
          questionsCacheKey(seriesId),
          (old) => {
            return update(old, {
              // @ts-expect-error type is not matching
              $splice: [
                // for this to be new line
                [insertAtIndex, 0, ...(newSlides as typeof questions)],
              ],
            });
          },
          true,
        );
      }

      return null;
    },

    [seriesId, publish, mutate],
  );

  return {
    addPublicTemplateToNewSeries,
    addPublicTemplateToOldSeries,
  };
};
