import { Box } from '@mentimeter/ragnar-ui/box';
import { Skeleton } from '@mentimeter/ragnar-ui/skeleton';

export const DetailsSkeleton = () => {
  return (
    <>
      <Skeleton mb={2} width="90%" />
      <Skeleton mb={4} width="20%" />
      <Skeleton mb={2} width="30%" />
      <Box width="100%" flexDirection="row">
        {Array(3)
          .fill(1)
          .map((_el, i) => (
            <Skeleton key={i} width="25%" mr={2} />
          ))}
      </Box>
    </>
  );
};
