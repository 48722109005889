import { type ReactNode } from 'react';
import { MarkdownInstructions } from '@mentimeter/ragnar-ui/markdown';
import {
  PopoverBody,
  PopoverContainer,
  PopoverRoot,
  PopoverTrigger,
} from '@mentimeter/ragnar-ui/popover';

export function MarkdownInstructionsPopover({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <PopoverRoot>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContainer>
        <PopoverBody>
          <MarkdownInstructions />
        </PopoverBody>
      </PopoverContainer>
    </PopoverRoot>
  );
}
