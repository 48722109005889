import { SlidePreview } from '@mentimeter/slide-preview/dynamic';
import {
  RevealWhenFirstVisible,
  SlidePreviewBoundary,
} from '@mentimeter/slide-preview/components';
import { useTemplatesSlideDeck } from '../../hooks/useTemplatesSlideDeck';

const RUNNING_IN_UNIT_TEST = process?.env?.['JEST_WORKER_ID'] !== undefined;

interface TemplateSlidePreviewProps {
  seriesId: string;
  displaySlideId: string;
}

export function TemplateSlidePreview({
  seriesId,
  displaySlideId,
}: TemplateSlidePreviewProps) {
  if (RUNNING_IN_UNIT_TEST) return null;

  return (
    <RevealWhenFirstVisible>
      <SlidePreviewBoundary context="recent-presentations">
        <TemplateSlidePreviewDataHandler
          seriesId={seriesId}
          displaySlideId={displaySlideId}
        />
      </SlidePreviewBoundary>
    </RevealWhenFirstVisible>
  );
}

function TemplateSlidePreviewDataHandler({
  seriesId,
  displaySlideId,
}: TemplateSlidePreviewProps) {
  const { series } = useTemplatesSlideDeck(seriesId, {
    swrConfig: { suspense: true },
  });

  return (
    <SlidePreview
      // Suspense is used, so series is guaranteed to be defined
      data={series!}
      displaySlideId={displaySlideId}
      exampleResultsMode="on-hover-always"
      enableTestVotesIndicator
      insetBorderRadius={2}
    />
  );
}
