import type {
  QuestionSlideType,
  QuestionType,
  Series,
} from '@mentimeter/http-clients';

export const getQuestionTypesMappedByName = (
  presentation: Series,
): string[] => {
  const questionTypes = presentation.questions
    .map((question) => {
      const { module_id: moduleId, type } = question;
      if (moduleId && type !== 'slide') return moduleId;
      if (type !== 'slide') return type;
      return;
    })
    .filter(
      (type, index, array) => type?.length && array.indexOf(type) === index,
    );
  const NAMES_MAP: Partial<Record<QuestionType | QuestionSlideType, string>> = {
    choices_images: 'Image Choice',
    choices: 'Multiple Choice',
    open: 'Open Ended',
    pin_on_image: 'Pin on Image',
    prioritisation: '100 Points',
    qfa: 'Q&A',
    quiz: 'Select Answer',
    quiz_open: 'Type Answer',
    quiz_leaderboard: 'Leaderboard',
    ranking: 'Ranking',
    rating: '2 x 2 Grid',
    scales: 'Scales',
    wordcloud: 'Word Cloud',
    metadata: 'Quick Form',
  };

  const QUESTION_MODULES_NAMES = {
    'numerical-question': 'Guess the Number',
    'pin-on-image': 'Pin on Image',
    'quiz-choices': 'Select Answer',
    'quiz-open': 'Type Answer',
    'quiz-leaderboard': 'Leaderboard',
  };

  const names = questionTypes?.map((type) => {
    // @ts-expect-error-auto TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
    return QUESTION_MODULES_NAMES[type] || NAMES_MAP[type];
  });
  return names;
};
