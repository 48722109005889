import type { Question } from '@mentimeter/http-clients';
import type {
  QuestionWithSlide as TemplatesQuestionWithSlide,
  Slide as TemplatesSlide,
} from '@mentimeter/templates-schema/templates';
import { isProxyInstance } from '../../../../utils/is-proxy-instance';
import { getCommonSlideCompatibilityHandler } from '../../../common/get-common-slide-compatibility-handler';
import { templatesSlidePropertyKeys } from './constants';
import { mapTemplatesSlidePropertyToQuestionProperty } from './map-templates-slide-property-to-question-property';

export function createTemplatesSlideCompatibilityLayer(
  target: Question | TemplatesSlide,
): TemplatesQuestionWithSlide {
  if (isProxyInstance(target)) {
    return target as TemplatesQuestionWithSlide;
  }

  return new Proxy(
    target,
    getCommonSlideCompatibilityHandler(
      templatesSlidePropertyKeys,
      mapTemplatesSlidePropertyToQuestionProperty,
    ),
  ) as TemplatesQuestionWithSlide;
}
