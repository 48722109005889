// This file is automatically generated. DO NOT EDIT.

import { type DuplicateSeries } from '../../../types/response/DuplicateSeries';
import { type DuplicateSlideDeck } from '../../../types/response/DuplicateSlideDeck';
import { fromSnakeKeys } from '@api/internal';

export interface PostDuplicateResponse {
  /** The duplicated series */
  series: DuplicateSeries;
  /** The duplicated slide deck, will be null if series is not migrated to a slide deck */
  slideDeck: DuplicateSlideDeck | null;
}

export async function postDuplicateResponse(
  response: Response,
): Promise<PostDuplicateResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as PostDuplicateResponse;
}
