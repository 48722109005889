// This file is automatically generated. DO NOT EDIT.

import { getCoreURL, toSnakeKeys } from '@api/internal';

export interface PostDuplicateRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The source of slides to import */
  source: {
    /** The region of the source */
    region?: 'eu' | 'us';
    /** The Id of the source Series */
    seriesId: string;
  };
  /** Additional tags */
  tags?: Array<string>;
  client?: string;
  trackContext?: string;
}

export function postDuplicateRequest(
  requestParams: PostDuplicateRequest,
): Request {
  const data = {
    source: toSnakeKeys(requestParams.source),
    tags: toSnakeKeys(requestParams.tags),
    client: toSnakeKeys(requestParams.client),
    track_context: toSnakeKeys(requestParams.trackContext),
  };

  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/templates/public/duplicate`;
  return new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
    body: JSON.stringify(data),
  });
}
