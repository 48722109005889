import type {
  PublicTemplateT,
  PublicTemplateWithSeriesT,
  PublicTemplatesCategoryT,
  UserProfileT,
} from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';
import { orderBy } from 'lodash';
import { type SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { useMemo } from 'react';

const TEMPLATES_REGION = 'eu';
const ALL_TEMPLATES_ID = 0;

export const ALL_TEMPLATES_CATEGORY: PublicTemplatesCategoryT = {
  id: ALL_TEMPLATES_ID,
  name: 'All templates',
  description:
    'Jump into a Menti curated for you instead of starting from scratch.',
  main_usage: null,
};

export const useUserProfile = () =>
  useSWRImmutable('user-profile', async () => {
    const { data } = await core().users.getUserProfile();
    return data;
  });

// All templates and categories exists in the same fixed region, so we configure a core client that goes only to that region.
const getCoreTemplatesClient = async () => core({ region: TEMPLATES_REGION });

export function useApiTemplates(
  skip: boolean,
  mainUsage: UserProfileT['main_usage'],
  options?: {
    include: { series: true };
    largeTemplates?: boolean;
  },
): SWRResponse<PublicTemplateWithSeriesT[]>;

export function useApiTemplates(
  skip: boolean,
  mainUsage: UserProfileT['main_usage'],
  options?: {
    include?: { series?: false };
    largeTemplates?: boolean;
  },
): SWRResponse<PublicTemplateT[]>;

export function useApiTemplates(
  skip: boolean,
  mainUsage: UserProfileT['main_usage'],
  options: {
    include?: {
      series?: boolean;
    };
    largeTemplates?: boolean;
  } = {},
): SWRResponse<PublicTemplateWithSeriesT[]> | SWRResponse<PublicTemplateT[]> {
  return useSWRImmutable(
    skip
      ? null
      : ['api-templates', mainUsage, options.include?.series ?? false],
    async () => {
      const coreTemplatesClient = await getCoreTemplatesClient();
      const {
        data: { data: templates },
      } = await coreTemplatesClient.publicTemplates.get(
        '?' +
          new URLSearchParams({
            ...(mainUsage && { main_usage: mainUsage }),
            ...(options.include && {
              include: Object.keys(options.include).join(','),
            }),
            ...(options.largeTemplates && {
              large_templates: 'true',
            }),
          }).toString(),
      );

      return templates;
    },
  );
}

export const useApiTemplateByCategory = (
  mainUsage: UserProfileT['main_usage'],
  categoryId: number,
) => {
  const { data: templates, ...rest } = useApiTemplates(false, mainUsage, {
    largeTemplates: true,
  });
  const filteredTemplates = useMemo(
    () =>
      templates?.filter((t) => {
        if (categoryId === ALL_TEMPLATES_CATEGORY.id) return true;
        return t.category_id === categoryId;
      }),
    [templates, categoryId],
  );
  const sortedTemplates = orderBy(filteredTemplates, ['category_id', 'name']);

  return { data: sortedTemplates, ...rest };
};

export const useApiTemplateCategories = (
  skip: boolean,
  mainUsage: UserProfileT['main_usage'],
) =>
  useSWRImmutable(skip ? null : `api-categories/${mainUsage}`, async () => {
    const coreTemplatesClient = await getCoreTemplatesClient();
    const {
      data: { data: displayedCategories },
    } = await coreTemplatesClient.publicTemplates.categories.get(
      `?main_usage=${mainUsage}`,
    );
    return orderBy(displayedCategories, 'id');
  });

export type PublicTemplateUseCase = 'business' | 'education';

const DEFAULT_MAIN_USAGE: PublicTemplateUseCase = 'business';

export function getSupportedMainUsage(
  userProfile?: UserProfileT,
): PublicTemplateUseCase {
  if (!userProfile) return DEFAULT_MAIN_USAGE;

  const mainUsage = userProfile.main_usage;
  if (mainUsage === 'business' || mainUsage === 'education') return mainUsage;

  return DEFAULT_MAIN_USAGE;
}

export function useApiTemplate(id: number | null) {
  return useSWRImmutable(id ? `api-template/${id}` : null, async () => {
    const coreTemplatesClient = await getCoreTemplatesClient();
    const { data: template } =
      await coreTemplatesClient.publicTemplates.template.get(id!);
    return template;
  });
}
