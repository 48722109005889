import type { RagnarButtonState } from '@mentimeter/ragnar-ui/button';
import { Button } from '@mentimeter/ragnar-ui/button';

export const AddTemplateButton = ({
  handleClickClone,
  addTemplateButtonState,
}: {
  handleClickClone: (e: React.MouseEvent) => void;
  addTemplateButtonState: RagnarButtonState | undefined;
}) => {
  return (
    <Button
      size="large"
      variant="primary"
      mb={[3, 2]}
      onClick={handleClickClone}
      state={addTemplateButtonState}
    >
      Use template
    </Button>
  );
};
