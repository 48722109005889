import {
  useActiveQuestionId,
  useActiveSeriesId,
} from '@mentimeter/core-hooks/use-ids';
import { EditorFlags, useEditorFlag } from '@mentimeter/core-global-state';
import { usePublicTemplatesActions } from '@mentimeter/template-hooks';
import { useCallback, useState } from 'react';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import type { ModalTrackingContextT } from '../types';

interface Props {
  isNewPresentationMode: boolean;
  context: ModalTrackingContextT;
  insertAtIndex: number;
  successCallback?: () => void;
  errorCallback?: (error: unknown) => void;
}
/**
 * @param isNewPresentationMode - Indicates if the presentation is newly created and does not have any slides.
 */
export const useCopyPublicTemplate = ({
  isNewPresentationMode,
  context,
  insertAtIndex,
  successCallback = () => null,
  errorCallback = () => null,
}: Props) => {
  const [state, setState] = useState<ButtonT['state']>();

  const [, setNewPresentationMode] = useEditorFlag(
    EditorFlags.NewPresentationMode,
  );
  const ERROR_RESET_DELAY = 2000;

  const activeSeriesId = useActiveSeriesId();
  const replaceQuestionId = useActiveQuestionId();

  const { addPublicTemplateToNewSeries, addPublicTemplateToOldSeries } =
    usePublicTemplatesActions(activeSeriesId);

  const replacePublicTemplate = useCallback(
    async (templateId: number) => {
      await addPublicTemplateToNewSeries({
        templateId,
        replaceQuestionId: replaceQuestionId as string,
        context,
      });
    },
    [addPublicTemplateToNewSeries, context, replaceQuestionId],
  );

  const insertPublicTemplate = useCallback(
    async (templateId: number) => {
      await addPublicTemplateToOldSeries({
        templateId,
        insertAtIndex,
        context,
      });
    },
    [addPublicTemplateToOldSeries, context, insertAtIndex],
  );

  const copyPublicTemplate = useCallback(
    async (templateId: number) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isNewPresentationMode
          ? await replacePublicTemplate(templateId)
          : await insertPublicTemplate(templateId);

        setState('success');
        setNewPresentationMode(false);
        successCallback?.();
      } catch (error) {
        captureException(
          new MentiError('Failed to duplicate template series', {
            feature: 'copy-template-in-editor',
            cause: { error },
          }),
        );
        setState('error');
        setTimeout(() => {
          setState(undefined);
        }, ERROR_RESET_DELAY);
        errorCallback?.(error);
      }
    },
    [
      isNewPresentationMode,
      replacePublicTemplate,
      insertPublicTemplate,
      setNewPresentationMode,
      successCallback,
      errorCallback,
    ],
  );

  return {
    copyPublicTemplate,
    state,
  };
};
