import React from 'react';
import { ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../box';
import { IconButton, type IconButtonProps } from '../icon-button';

export interface ChevronIconProps extends Pick<IconButtonProps, 'onClick'> {
  show: boolean | undefined;
  togglePlacement: 'rightStart' | 'rightEnd' | undefined;
}

export function ChevronIcon({
  show,
  onClick,
  togglePlacement = 'rightStart',
}: ChevronIconProps) {
  const defaultClasses = [
    'transform',
    'transition-transform',
    'duration-200',
    'ease-in-out',
  ];

  return (
    <Box
      className={clsx(
        togglePlacement === 'rightEnd' && 'ml-auto',
        togglePlacement === 'rightStart' && 'ml-1',
      )}
    >
      <IconButton variant="secondary" onClick={onClick}>
        <Box className={clsx(defaultClasses, show ? 'rotate-180' : 'rotate-0')}>
          <ChevronDownIcon color="inherit" />
        </Box>
      </IconButton>
    </Box>
  );
}
