// Local and session storage
export const CURRENT_URL_STORAGE_KEY = 'currentUrl';
export const PROFANITY_CACHE_STORAGE_KEY_PREFIX = 'localSwears-';
export const PREVIEW_TOUR_SEEN_STORAGE_KEY = 'preview_tour_seen';
export const CLOSE_DASHBOARD_TEMPLATES_STORAGE_KEY =
  'mm-close-dashboard-templates';
export const MEMBER_STATUS_FILTER_STORAGE_KEY = 'member-status-selected-filter';
export const MEMBER_STATUS_SEARCH_STORAGE_KEY = 'members-status-search-query';
export const POWERPOINT_DEV_STORAGE_KEY = 'dev';
export const SKIP_TEMPLATE_MODAL = 'mm-skip-template-modal';
export const HELLO_LOGIN_STORAGE_KEY = 'hello';
export const CLOSE_RECOMMENDED_TEMPLATES_KEY = 'mm-close-recommended-templates';
export const EDITOR_RESULTS_POPOVER = 'mm-editor-results-popover';
export const COLLAPSE_DASHBOARD_TEMPLATE_PANEL_KEY =
  'mm-collapse-dashboard-template-panel';
export const SPLIT_OVERRIDE_STORAGE_KEY = 'menti_split_overrides';
